<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <CoreContainer>
        <BaseContainerContent
            :padding="{ vertical: 'large' }"
            width="narrow"
            tag="article"
        >
            <div
                class="vut-wysiwyg-text"
                v-html="staticPageResponse?.getItem()?.content"
            />
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
const { page } = useCurrentPage()

const { data: staticPageResponse } = await useStaticPagesApiService().forId(page.value?.model?.id).useGet()

</script>
